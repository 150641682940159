import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { OptionalGatsbyImage } from '@/atomic/atoms/OptionalGatsbyImage';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import { CommonAButton } from './CommonElement';
import Nl2Br from './Nl2Br';

const ProjectHeroWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-bottom: 16px;
  overflow: hidden;
  color: #fff;
  @media (min-width: 1024px) {
    padding-bottom: 0px;
  }
`;

const ProjectHeroContent = styled.div`
  body & {
    text-align: center;
    .ProjectHero__Image {
      display: block;
      width: 80%;
      margin: 0 auto;
    }
    /* PC */
    @media (min-width: 1024px) {
      display: flex;
      align-items: center;
      width: 90%;
      max-width: 1024px;
      height: 600px;
      margin: 0 auto;
      .ProjectHero__Image {
        width: 50%;
        height: auto;
      }
    }
  }
`;

const ProjectHeroMessage = styled.div`
  body & {
    margin-bottom: 20px;
    text-align: center;
    /* PC */
    @media (min-width: 1024px) {
      width: 50%;
      text-align: left;
    }
  }
`;

const ProjectHeroMessageMain = styled.h2`
  body & {
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 300;
    line-height: 1.4;
    /* PC */
    @media (min-width: 1024px) {
      font-size: 36px;
    }
  }
`;

const ProjectHeroMessageSub = styled.p`
  body & {
    padding: 0;
    margin: 0 0 20px;
    font-size: 16px;
    /* PC */
    @media (min-width: 1024px) {
      margin-bottom: 30px;
      font-size: 24px;
    }
  }
`;

const ProjectHeroMessageLink = styled(CommonAButton)`
  body & {
    display: block;
    width: 70%;
    margin: 0 auto 40px;
    line-height: 60px;
    text-align: center;
    text-decoration: none;
    background: #fff;
    border-radius: 30px;
    /* PC */
    @media (min-width: 1024px) {
      width: 400px !important;
      margin: 0 auto 0 0;
    }
  }
`;

const ProjectHeroCalendlyButton = styled.button`
  body & {
    display: block;
    width: 70%;
    margin: 0 auto 40px;
    line-height: 60px;
    color: #0093bb;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background: #fff;
    border: none;
    border-radius: 30px;
    /* TAB */
    @media (min-width: 768px) {
      font-size: 20px;
    }
    /* PC */
    @media (min-width: 1024px) {
      width: 400px !important;
      margin: 0 auto 0 0;
    }
  }
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 0%;
  left: 50%;
  z-index: -100;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, 0%);
`;

const OptionalImage = styled(OptionalGatsbyImage)`
  @media (max-width: 640px) {
    width: 90%;
  }
`;

const HERO_BANNER_DATA_QUERY = graphql`
  query HeroBannerData {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-hero-banner" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            freeTrialButton {
              label
              url
            }
            heroSectionText1
            heroSectionText2
            heroImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {
  calendlyModalIsOpen?: boolean;
  updateCalendlyModalIsOpen?: (bool: boolean) => void;
};

const HeroBanner: React.FC<Props> = ({
  calendlyModalIsOpen,
  updateCalendlyModalIsOpen,
}) => {
  const data = useStaticQuery<GatsbyTypes.HeroBannerDataQuery>(
    HERO_BANNER_DATA_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);
  const BreakPoint = 768;
  const VideoSource = () => {
    const isBrowser = typeof window !== 'undefined' ? window.innerWidth : 1280;
    const [width, setWidth] = useState<number>(isBrowser);
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };

    useEffect(() => {
      window.addEventListener(`resize`, updateWidth, {
        capture: false,
        passive: true,
      });

      return () => window.removeEventListener(`resize`, updateWidth);
    });
    if (BreakPoint > width) {
      return (
        <source
          src="https://assets.ricoh360.com/video/upload/vc_auto,c_crop,g_center,h_720/c_scale,w_640/v1/front/projects/top-hero.mp4"
          type="video/mp4"
        />
      );
    }
    return (
      <source
        src="https://assets.ricoh360.com/video/upload/vc_auto,c_crop,g_center,h_720/v1/front/projects/top-hero.mp4"
        type="video/mp4"
      />
    );
  };

  return (
    <ProjectHeroWrapper data-cy="projectsPage">
      <BackgroundVideo autoPlay muted loop playsInline>
        {VideoSource()}
      </BackgroundVideo>
      <ProjectHeroContent>
        <ProjectHeroMessage>
          <ProjectHeroMessageMain>
            <Nl2Br text={frontmatter?.heroSectionText1} />
          </ProjectHeroMessageMain>
          <ProjectHeroMessageSub>
            <Nl2Br text={frontmatter?.heroSectionText2} />
          </ProjectHeroMessageSub>
          {frontmatter?.freeTrialButton?.url === 'button' ? (
            <ProjectHeroCalendlyButton
              onClick={() => {
                updateCalendlyModalIsOpen?.(!calendlyModalIsOpen);
              }}
            >
              {frontmatter?.freeTrialButton?.label}
            </ProjectHeroCalendlyButton>
          ) : (
            <ProjectHeroMessageLink
              href={frontmatter?.freeTrialButton?.url}
              className={'Button'}
              target="_blank"
            >
              {frontmatter?.freeTrialButton?.label}
            </ProjectHeroMessageLink>
          )}
        </ProjectHeroMessage>
        <OptionalImage
          image={frontmatter?.heroImage}
          alt={frontmatter?.heroSectionText1 || ''}
        />
      </ProjectHeroContent>
    </ProjectHeroWrapper>
  );
};

export default HeroBanner;
